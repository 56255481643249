export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
    const store = useStore();
    store.setError({
      error,
      instance,
      info,
    });
  };
});

<script setup lang="ts">
useSeoMeta({
  title: "Pagina nu a fost gasita",
  description: "Ne pare rau, dar pagina pe care o cauti nu a fost gasita.",
});

defineProps({
  error: {
    type: Object,
    required: true,
  },
});

useHead({
  htmlAttrs: {
    lang: "ro",
  },
});
</script>

<template>
  <div>
    <UMain>
      <UContainer>
        <UPage>
          <UPageError :error="error" />
        </UPage>
      </UContainer>
    </UMain>

    <UNotifications />
  </div>
</template>

export const useStore = defineStore('main', {
  persist: {
    storage: persistedState.localStorage,
    paths: [
      "token",
      "validated",
      "user",
      "viewing",
      "checkinNotification",
      "checkins",
    ],
  },
  state: () => ({
    error: null,
    token: "",
    validated: false,
    user: null,
    viewing: "",
    checkinNotification: new Date().toLocaleDateString('ro-RO').split("T")[0],
    checkins: {} as any,
  }),
  actions: {
    init() {
      this.setColors();
    },
    setColors(primary: string, gray: string) {
      const preferences = usePreferences();
      if (!primary) primary = preferences.uiPrimary;
      if (!gray) gray = preferences.uiGray;

      preferences.set("uiPrimary", primary);
      preferences.set("uiGray", gray);

      const appConfig = useAppConfig();
      appConfig.ui.primary = primary;
      appConfig.ui.gray = gray;
    },
    setError(error: any) {
      this.error = error;
    },
    login(data: any) {
      if (data.access_token) {
        this.token = data.access_token;
      }
      this.user = data.data;
      this.validated = true;
    },
    setRecoveryToken(recoveryToken: any) {
      this.token = recoveryToken;
    },
    validate(to: any) {
      if (!to.path.includes("logout")) {
        useApi("GET", "/auth/validate").then((res: any) => {
          if (res) {
            this.validated = true;
            this.login({
              data: res,
            });
          } else {
            this.logout();
          }
          // if (to) {
          //   navigateTo(to)
          // }
        });
      }
    },
    logout(): void {
      this.token = "";
      this.viewing = "";
      this.user = null;
      this.validated = false;

      if (import.meta.client) {
        // navigateTo('/auth/login', { replace: true })
        navigateTo("/");
      }
    },
    view(to: string) {
      this.viewing = to;
      navigateTo("/" + to);
    },
    addCheckin() {
      // clear user's checkins object
      // TODO: remove eventually 
      if (Object.keys(this.checkins).includes(undefined) || Object.keys(this.checkins).includes("undefined")) {
        delete this.checkins.undefined
      }

      if (this.user && this.user.id) {
        if (this.checkins[this.user.id]) {
          this.checkins[this.user.id] += 1
        } else {
          this.checkins[this.user.id] = 1
        }
      }
    },
    updateCheckinNotification() {
      this.checkinNotification = new Date().toLocaleDateString('ro-RO').split("T")[0];
    },
  },
});

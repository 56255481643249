import { default as employeesD5pI5IxrgEMeta } from "/vercel/path0/pages/accounting/employees.vue?macro=true";
import { default as hours1iqe4qCrveMeta } from "/vercel/path0/pages/accounting/hours.vue?macro=true";
import { default as indexpeayJUkuR1Meta } from "/vercel/path0/pages/accounting/index.vue?macro=true";
import { default as indext7bvwEkuVDMeta } from "/vercel/path0/pages/accounting/other/index.vue?macro=true";
import { default as invoicessEmvUlxsZJMeta } from "/vercel/path0/pages/accounting/other/invoices.vue?macro=true";
import { default as offersyZUosbx1rNMeta } from "/vercel/path0/pages/accounting/other/offers.vue?macro=true";
import { default as othersVKri0tQ2PMeta } from "/vercel/path0/pages/accounting/other.vue?macro=true";
import { default as allku4xpgoM1FMeta } from "/vercel/path0/pages/accounting/reports/all.vue?macro=true";
import { default as indexO8UWn51ttkMeta } from "/vercel/path0/pages/accounting/reports/index.vue?macro=true";
import { default as userC6X57ZlBjgMeta } from "/vercel/path0/pages/accounting/reports/user.vue?macro=true";
import { default as invoicesD52Ng0c9QQMeta } from "/vercel/path0/pages/administration/invoices.vue?macro=true";
import { default as offersGMQ2tkDxvfMeta } from "/vercel/path0/pages/administration/offers.vue?macro=true";
import { default as usersc4pv1aqc2yMeta } from "/vercel/path0/pages/administration/users.vue?macro=true";
import { default as administrationNguiTSSTT7Meta } from "/vercel/path0/pages/administration.vue?macro=true";
import { default as checkin6OVgRTDxpKMeta } from "/vercel/path0/pages/attendance/checkin.vue?macro=true";
import { default as day_46usersC2A9q1L10rMeta } from "/vercel/path0/pages/attendance/day.users.vue?macro=true";
import { default as dayKkgt3z9z8aMeta } from "/vercel/path0/pages/attendance/day.vue?macro=true";
import { default as employeessePlFohYpBMeta } from "/vercel/path0/pages/attendance/employees.vue?macro=true";
import { default as indexNQk1PattKtMeta } from "/vercel/path0/pages/attendance/index.vue?macro=true";
import { default as locationsfSVigtwgQIMeta } from "/vercel/path0/pages/attendance/locations.vue?macro=true";
import { default as teamnvyEH78dzjMeta } from "/vercel/path0/pages/attendance/team.vue?macro=true";
import { default as indexkppHktB81xMeta } from "/vercel/path0/pages/auth/index.vue?macro=true";
import { default as loginILQdBdZzRsMeta } from "/vercel/path0/pages/auth/login.vue?macro=true";
import { default as logout5qQMyX1mvLMeta } from "/vercel/path0/pages/auth/logout.vue?macro=true";
import { default as _91recoveryToken_93EnEzWlIDJWMeta } from "/vercel/path0/pages/auth/recover/[recoveryToken].vue?macro=true";
import { default as registerUZDCsj62s6Meta } from "/vercel/path0/pages/auth/register.vue?macro=true";
import { default as resetTB1mShUuL3Meta } from "/vercel/path0/pages/auth/reset.vue?macro=true";
import { default as analyticsNqyqBCsD68Meta } from "/vercel/path0/pages/boxcatering/analytics.vue?macro=true";
import { default as categoriessmMTb8biubMeta } from "/vercel/path0/pages/boxcatering/categories.vue?macro=true";
import { default as herojUHafVg6sZMeta } from "/vercel/path0/pages/boxcatering/hero.vue?macro=true";
import { default as indexWaSZeHCj8CMeta } from "/vercel/path0/pages/boxcatering/index.vue?macro=true";
import { default as ingredientsu6vU0FQKpBMeta } from "/vercel/path0/pages/boxcatering/ingredients.vue?macro=true";
import { default as inquiriesrCcZY57xMzMeta } from "/vercel/path0/pages/boxcatering/inquiries.vue?macro=true";
import { default as items7CLwfuGRaxMeta } from "/vercel/path0/pages/boxcatering/items.vue?macro=true";
import { default as _91id_93q7upyW58wEMeta } from "/vercel/path0/pages/boxcatering/menus/[id].vue?macro=true";
import { default as indexOUgrDaEaANMeta } from "/vercel/path0/pages/boxcatering/menus/index.vue?macro=true";
import { default as _91id_93VOOt1j3fitMeta } from "/vercel/path0/pages/boxcatering/offers/[id].vue?macro=true";
import { default as indexi0q48j2hg2Meta } from "/vercel/path0/pages/boxcatering/offers/index.vue?macro=true";
import { default as ordersuKcLeBIh06Meta } from "/vercel/path0/pages/boxcatering/orders.vue?macro=true";
import { default as shopTszBVSJ7t6Meta } from "/vercel/path0/pages/boxcatering/shop.vue?macro=true";
import { default as indexRIIpWdUktDMeta } from "/vercel/path0/pages/dashboard/index.vue?macro=true";
import { default as invoicesdRobIlgaBOMeta } from "/vercel/path0/pages/dashboard/invoices.vue?macro=true";
import { default as planN8nNoxonnXMeta } from "/vercel/path0/pages/dashboard/plan.vue?macro=true";
import { default as ticketsLvE8obJeSuMeta } from "/vercel/path0/pages/dashboard/tickets.vue?macro=true";
import { default as vouchers3emh98BTm0Meta } from "/vercel/path0/pages/dashboard/vouchers.vue?macro=true";
import { default as calendaryVM3QRpZ9OMeta } from "/vercel/path0/pages/hotel/bookings/calendar.vue?macro=true";
import { default as index8ocMAOLN4LMeta } from "/vercel/path0/pages/hotel/bookings/index.vue?macro=true";
import { default as listEj6y9zhqCHMeta } from "/vercel/path0/pages/hotel/bookings/list.vue?macro=true";
import { default as indexMMwYK8bZYXMeta } from "/vercel/path0/pages/hotel/index.vue?macro=true";
import { default as roomsoD41IfDkzgMeta } from "/vercel/path0/pages/hotel/rooms.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91id_93F6ENo2uejcMeta } from "/vercel/path0/pages/marketing/campaigns/[id].vue?macro=true";
import { default as indexPOnmVhndwwMeta } from "/vercel/path0/pages/marketing/campaigns/index.vue?macro=true";
import { default as clientslEW6E99XpRMeta } from "/vercel/path0/pages/marketing/clients.vue?macro=true";
import { default as indexYKUHLTPU0RMeta } from "/vercel/path0/pages/marketing/index.vue?macro=true";
import { default as analyticsj4SEsirWofMeta } from "/vercel/path0/pages/millerscake/analytics.vue?macro=true";
import { default as categoriesI7LwdCasVeMeta } from "/vercel/path0/pages/millerscake/categories.vue?macro=true";
import { default as indexQbINWeih18Meta } from "/vercel/path0/pages/millerscake/index.vue?macro=true";
import { default as items6mebPhzMy9Meta } from "/vercel/path0/pages/millerscake/items.vue?macro=true";
import { default as _91id_93lTNfN9fo9PMeta } from "/vercel/path0/pages/millerscake/offers/[id].vue?macro=true";
import { default as indexa6TuA5LskUMeta } from "/vercel/path0/pages/millerscake/offers/index.vue?macro=true";
import { default as ordersy5PhdKhX1FMeta } from "/vercel/path0/pages/millerscake/orders.vue?macro=true";
import { default as indexhEOYUchS1OMeta } from "/vercel/path0/pages/procurement/index.vue?macro=true";
import { default as profile3g7qqlrA4oMeta } from "/vercel/path0/pages/settings/profile.vue?macro=true";
import { default as securityY4oknXfXXwMeta } from "/vercel/path0/pages/settings/security.vue?macro=true";
import { default as systemzFdFwMZRsfMeta } from "/vercel/path0/pages/settings/system.vue?macro=true";
import { default as settingsyZZu4Rd1aAMeta } from "/vercel/path0/pages/settings.vue?macro=true";
import { default as analyticso4DVeoZiz8Meta } from "/vercel/path0/pages/venues/analytics.vue?macro=true";
import { default as categoriesVEEmjWHeiMMeta } from "/vercel/path0/pages/venues/categories.vue?macro=true";
import { default as _91id_93ChumaKVZGNMeta } from "/vercel/path0/pages/venues/events/[id].vue?macro=true";
import { default as calendar_45oldOI3GOBSwByMeta } from "/vercel/path0/pages/venues/events/calendar-old.vue?macro=true";
import { default as calendarDPqf9lHbqSMeta } from "/vercel/path0/pages/venues/events/calendar.vue?macro=true";
import { default as indexoI1XfOOxreMeta } from "/vercel/path0/pages/venues/events/index.vue?macro=true";
import { default as listvzbGZxWNQrMeta } from "/vercel/path0/pages/venues/events/list.vue?macro=true";
import { default as indexpwPeHA0FHzMeta } from "/vercel/path0/pages/venues/index.vue?macro=true";
import { default as analyticsA08U7Md5hMMeta } from "/vercel/path0/pages/venues/leads/analytics.vue?macro=true";
import { default as calendarvzNp5OaTKQMeta } from "/vercel/path0/pages/venues/leads/calendar.vue?macro=true";
import { default as call_45listpKFAyJwrVfMeta } from "/vercel/path0/pages/venues/leads/call-list.vue?macro=true";
import { default as funnelaVJUopRa3SMeta } from "/vercel/path0/pages/venues/leads/funnel.vue?macro=true";
import { default as tableqROFKRppNaMeta } from "/vercel/path0/pages/venues/leads/table.vue?macro=true";
import { default as locations1QZIijzlTIMeta } from "/vercel/path0/pages/venues/locations.vue?macro=true";
import { default as _91id_93jj19QEwlt1Meta } from "/vercel/path0/pages/venues/menus/[id].vue?macro=true";
import { default as indexj7yZ1l4m4RMeta } from "/vercel/path0/pages/venues/menus/index.vue?macro=true";
import { default as _91id_93iQhd0TgFOMMeta } from "/vercel/path0/pages/venues/offers/[id].vue?macro=true";
import { default as indexDwxruDVcj5Meta } from "/vercel/path0/pages/venues/offers/index.vue?macro=true";
import { default as templatesiIUNZxho1HMeta } from "/vercel/path0/pages/venues/templates.vue?macro=true";
export default [
  {
    name: "accounting-employees",
    path: "/accounting/employees",
    component: () => import("/vercel/path0/pages/accounting/employees.vue")
  },
  {
    name: "accounting-hours",
    path: "/accounting/hours",
    component: () => import("/vercel/path0/pages/accounting/hours.vue")
  },
  {
    name: "accounting",
    path: "/accounting",
    component: () => import("/vercel/path0/pages/accounting/index.vue")
  },
  {
    name: othersVKri0tQ2PMeta?.name,
    path: "/accounting/other",
    component: () => import("/vercel/path0/pages/accounting/other.vue"),
    children: [
  {
    name: "accounting-other",
    path: "",
    component: () => import("/vercel/path0/pages/accounting/other/index.vue")
  },
  {
    name: "accounting-other-invoices",
    path: "invoices",
    component: () => import("/vercel/path0/pages/accounting/other/invoices.vue")
  },
  {
    name: "accounting-other-offers",
    path: "offers",
    component: () => import("/vercel/path0/pages/accounting/other/offers.vue")
  }
]
  },
  {
    name: "accounting-reports-all",
    path: "/accounting/reports/all",
    component: () => import("/vercel/path0/pages/accounting/reports/all.vue")
  },
  {
    name: "accounting-reports",
    path: "/accounting/reports",
    component: () => import("/vercel/path0/pages/accounting/reports/index.vue")
  },
  {
    name: "accounting-reports-user",
    path: "/accounting/reports/user",
    component: () => import("/vercel/path0/pages/accounting/reports/user.vue")
  },
  {
    name: "administration",
    path: "/administration",
    component: () => import("/vercel/path0/pages/administration.vue"),
    children: [
  {
    name: "administration-invoices",
    path: "invoices",
    component: () => import("/vercel/path0/pages/administration/invoices.vue")
  },
  {
    name: "administration-offers",
    path: "offers",
    component: () => import("/vercel/path0/pages/administration/offers.vue")
  },
  {
    name: "administration-users",
    path: "users",
    component: () => import("/vercel/path0/pages/administration/users.vue")
  }
]
  },
  {
    name: "attendance-checkin",
    path: "/attendance/checkin",
    component: () => import("/vercel/path0/pages/attendance/checkin.vue")
  },
  {
    name: "attendance-day.users",
    path: "/attendance/day.users",
    component: () => import("/vercel/path0/pages/attendance/day.users.vue")
  },
  {
    name: "attendance-day",
    path: "/attendance/day",
    component: () => import("/vercel/path0/pages/attendance/day.vue")
  },
  {
    name: "attendance-employees",
    path: "/attendance/employees",
    component: () => import("/vercel/path0/pages/attendance/employees.vue")
  },
  {
    name: "attendance",
    path: "/attendance",
    component: () => import("/vercel/path0/pages/attendance/index.vue")
  },
  {
    name: "attendance-locations",
    path: "/attendance/locations",
    component: () => import("/vercel/path0/pages/attendance/locations.vue")
  },
  {
    name: "attendance-team",
    path: "/attendance/team",
    component: () => import("/vercel/path0/pages/attendance/team.vue")
  },
  {
    name: "auth",
    path: "/auth",
    component: () => import("/vercel/path0/pages/auth/index.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginILQdBdZzRsMeta || {},
    component: () => import("/vercel/path0/pages/auth/login.vue")
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    component: () => import("/vercel/path0/pages/auth/logout.vue")
  },
  {
    name: "auth-recover-recoveryToken",
    path: "/auth/recover/:recoveryToken()",
    meta: _91recoveryToken_93EnEzWlIDJWMeta || {},
    component: () => import("/vercel/path0/pages/auth/recover/[recoveryToken].vue")
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerUZDCsj62s6Meta || {},
    component: () => import("/vercel/path0/pages/auth/register.vue")
  },
  {
    name: "auth-reset",
    path: "/auth/reset",
    meta: resetTB1mShUuL3Meta || {},
    component: () => import("/vercel/path0/pages/auth/reset.vue")
  },
  {
    name: "boxcatering-analytics",
    path: "/boxcatering/analytics",
    component: () => import("/vercel/path0/pages/boxcatering/analytics.vue")
  },
  {
    name: "boxcatering-categories",
    path: "/boxcatering/categories",
    component: () => import("/vercel/path0/pages/boxcatering/categories.vue")
  },
  {
    name: "boxcatering-hero",
    path: "/boxcatering/hero",
    component: () => import("/vercel/path0/pages/boxcatering/hero.vue")
  },
  {
    name: "boxcatering",
    path: "/boxcatering",
    component: () => import("/vercel/path0/pages/boxcatering/index.vue")
  },
  {
    name: "boxcatering-ingredients",
    path: "/boxcatering/ingredients",
    component: () => import("/vercel/path0/pages/boxcatering/ingredients.vue")
  },
  {
    name: "boxcatering-inquiries",
    path: "/boxcatering/inquiries",
    component: () => import("/vercel/path0/pages/boxcatering/inquiries.vue")
  },
  {
    name: "boxcatering-items",
    path: "/boxcatering/items",
    component: () => import("/vercel/path0/pages/boxcatering/items.vue")
  },
  {
    name: "boxcatering-menus-id",
    path: "/boxcatering/menus/:id()",
    component: () => import("/vercel/path0/pages/boxcatering/menus/[id].vue")
  },
  {
    name: "boxcatering-menus",
    path: "/boxcatering/menus",
    component: () => import("/vercel/path0/pages/boxcatering/menus/index.vue")
  },
  {
    name: "boxcatering-offers-id",
    path: "/boxcatering/offers/:id()",
    component: () => import("/vercel/path0/pages/boxcatering/offers/[id].vue")
  },
  {
    name: "boxcatering-offers",
    path: "/boxcatering/offers",
    component: () => import("/vercel/path0/pages/boxcatering/offers/index.vue")
  },
  {
    name: "boxcatering-orders",
    path: "/boxcatering/orders",
    component: () => import("/vercel/path0/pages/boxcatering/orders.vue")
  },
  {
    name: "boxcatering-shop",
    path: "/boxcatering/shop",
    component: () => import("/vercel/path0/pages/boxcatering/shop.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/vercel/path0/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-invoices",
    path: "/dashboard/invoices",
    component: () => import("/vercel/path0/pages/dashboard/invoices.vue")
  },
  {
    name: "dashboard-plan",
    path: "/dashboard/plan",
    component: () => import("/vercel/path0/pages/dashboard/plan.vue")
  },
  {
    name: "dashboard-tickets",
    path: "/dashboard/tickets",
    component: () => import("/vercel/path0/pages/dashboard/tickets.vue")
  },
  {
    name: "dashboard-vouchers",
    path: "/dashboard/vouchers",
    component: () => import("/vercel/path0/pages/dashboard/vouchers.vue")
  },
  {
    name: "hotel-bookings-calendar",
    path: "/hotel/bookings/calendar",
    component: () => import("/vercel/path0/pages/hotel/bookings/calendar.vue")
  },
  {
    name: "hotel-bookings",
    path: "/hotel/bookings",
    component: () => import("/vercel/path0/pages/hotel/bookings/index.vue")
  },
  {
    name: "hotel-bookings-list",
    path: "/hotel/bookings/list",
    component: () => import("/vercel/path0/pages/hotel/bookings/list.vue")
  },
  {
    name: "hotel",
    path: "/hotel",
    component: () => import("/vercel/path0/pages/hotel/index.vue")
  },
  {
    name: "hotel-rooms",
    path: "/hotel/rooms",
    component: () => import("/vercel/path0/pages/hotel/rooms.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "marketing-campaigns-id",
    path: "/marketing/campaigns/:id()",
    component: () => import("/vercel/path0/pages/marketing/campaigns/[id].vue")
  },
  {
    name: "marketing-campaigns",
    path: "/marketing/campaigns",
    component: () => import("/vercel/path0/pages/marketing/campaigns/index.vue")
  },
  {
    name: "marketing-clients",
    path: "/marketing/clients",
    component: () => import("/vercel/path0/pages/marketing/clients.vue")
  },
  {
    name: "marketing",
    path: "/marketing",
    component: () => import("/vercel/path0/pages/marketing/index.vue")
  },
  {
    name: "millerscake-analytics",
    path: "/millerscake/analytics",
    component: () => import("/vercel/path0/pages/millerscake/analytics.vue")
  },
  {
    name: "millerscake-categories",
    path: "/millerscake/categories",
    component: () => import("/vercel/path0/pages/millerscake/categories.vue")
  },
  {
    name: "millerscake",
    path: "/millerscake",
    component: () => import("/vercel/path0/pages/millerscake/index.vue")
  },
  {
    name: "millerscake-items",
    path: "/millerscake/items",
    component: () => import("/vercel/path0/pages/millerscake/items.vue")
  },
  {
    name: "millerscake-offers-id",
    path: "/millerscake/offers/:id()",
    component: () => import("/vercel/path0/pages/millerscake/offers/[id].vue")
  },
  {
    name: "millerscake-offers",
    path: "/millerscake/offers",
    component: () => import("/vercel/path0/pages/millerscake/offers/index.vue")
  },
  {
    name: "millerscake-orders",
    path: "/millerscake/orders",
    component: () => import("/vercel/path0/pages/millerscake/orders.vue")
  },
  {
    name: "procurement",
    path: "/procurement",
    component: () => import("/vercel/path0/pages/procurement/index.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/vercel/path0/pages/settings.vue"),
    children: [
  {
    name: "settings-profile",
    path: "profile",
    component: () => import("/vercel/path0/pages/settings/profile.vue")
  },
  {
    name: "settings-security",
    path: "security",
    component: () => import("/vercel/path0/pages/settings/security.vue")
  },
  {
    name: "settings-system",
    path: "system",
    component: () => import("/vercel/path0/pages/settings/system.vue")
  }
]
  },
  {
    name: "venues-analytics",
    path: "/venues/analytics",
    component: () => import("/vercel/path0/pages/venues/analytics.vue")
  },
  {
    name: "venues-categories",
    path: "/venues/categories",
    component: () => import("/vercel/path0/pages/venues/categories.vue")
  },
  {
    name: "venues-events-id",
    path: "/venues/events/:id()",
    component: () => import("/vercel/path0/pages/venues/events/[id].vue")
  },
  {
    name: "venues-events-calendar-old",
    path: "/venues/events/calendar-old",
    component: () => import("/vercel/path0/pages/venues/events/calendar-old.vue")
  },
  {
    name: "venues-events-calendar",
    path: "/venues/events/calendar",
    component: () => import("/vercel/path0/pages/venues/events/calendar.vue")
  },
  {
    name: "venues-events",
    path: "/venues/events",
    component: () => import("/vercel/path0/pages/venues/events/index.vue")
  },
  {
    name: "venues-events-list",
    path: "/venues/events/list",
    component: () => import("/vercel/path0/pages/venues/events/list.vue")
  },
  {
    name: "venues",
    path: "/venues",
    component: () => import("/vercel/path0/pages/venues/index.vue")
  },
  {
    name: "venues-leads-analytics",
    path: "/venues/leads/analytics",
    component: () => import("/vercel/path0/pages/venues/leads/analytics.vue")
  },
  {
    name: "venues-leads-calendar",
    path: "/venues/leads/calendar",
    component: () => import("/vercel/path0/pages/venues/leads/calendar.vue")
  },
  {
    name: "venues-leads-call-list",
    path: "/venues/leads/call-list",
    component: () => import("/vercel/path0/pages/venues/leads/call-list.vue")
  },
  {
    name: "venues-leads-funnel",
    path: "/venues/leads/funnel",
    component: () => import("/vercel/path0/pages/venues/leads/funnel.vue")
  },
  {
    name: "venues-leads-table",
    path: "/venues/leads/table",
    component: () => import("/vercel/path0/pages/venues/leads/table.vue")
  },
  {
    name: "venues-locations",
    path: "/venues/locations",
    component: () => import("/vercel/path0/pages/venues/locations.vue")
  },
  {
    name: "venues-menus-id",
    path: "/venues/menus/:id()",
    component: () => import("/vercel/path0/pages/venues/menus/[id].vue")
  },
  {
    name: "venues-menus",
    path: "/venues/menus",
    component: () => import("/vercel/path0/pages/venues/menus/index.vue")
  },
  {
    name: "venues-offers-id",
    path: "/venues/offers/:id()",
    component: () => import("/vercel/path0/pages/venues/offers/[id].vue")
  },
  {
    name: "venues-offers",
    path: "/venues/offers",
    component: () => import("/vercel/path0/pages/venues/offers/index.vue")
  },
  {
    name: "venues-templates",
    path: "/venues/templates",
    component: () => import("/vercel/path0/pages/venues/templates.vue")
  }
]
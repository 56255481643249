import * as Sentry from "@sentry/browser";

export default defineNuxtPlugin((nuxtApp) => {
  const release = `<alexagroup-admin>@${new Date().toISOString()}`;

  if (process.env.NODE_ENV !== "development") {
    Sentry.init({
      dsn: "https://920c75b191bb0270f524a79c01bcffcf@o577952.ingest.us.sentry.io/4506869221490688",
      release,
      environment: process.env.NODE_ENV,
      sampleRate: 1,
      tracesSampleRate: 1,
      ignoreErrors: [
        "\bcoreDirector\b",
        "\bFailed to fetch\b",
        "\bvalid JavaScript MIME type\b",
        "\bCouldn't resolve component\b",
        "\b_getOneSignalAndSubscriptionIds\b",
      ]
    });
  }
});

import OneSignalVuePlugin from '@onesignal/onesignal-vue3'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  if (config.app.buildId !== "dev") {
    nuxtApp.vueApp.use(OneSignalVuePlugin, {
      appId: config.public.onesignalAppId,
      allowLocalhostAsSecureOrigin: true
    })
  }
})
